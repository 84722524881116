import { View, Pressable, StyleSheet } from 'react-native';
import StartIcon from '../icons/startIcon';
import TabItem from './TabItem';
import CarIconNormal from '../icons/carIconNormal';
import DriverIconNormal from '../icons/driverIconNormal';
import QuoteIconNormal from '../icons/quoteIconNormal';
import CarIconActive from '../icons/carIconActive';
import DriverIconActive from '../icons/driverIconActive';
import QuoteIconActive from '../icons/quoteIconActive';
import CompleteIcon from '../icons/completeIcon';
import { AutoTabType } from '../constants/autoTabType';
import { QuoteInfoType } from '../constants/quoteInfoType';
import { isMobile } from '../helpers/deviceUtils';

const isMobileDevice = isMobile();

const AutoTabData = [
    {
        title: "Start",
        type: QuoteInfoType.Start,
        isComplete: false,
    },
    {
        title: "You, continued",
        type: QuoteInfoType.Car,
        isComplete: false,
    },
    {
        title: "Final",
        type: QuoteInfoType.Quote,
        isComplete: false,
    }
]

const AutoTab = (props) => {

    const getIcon = (type, isActive) => {
        let icon = null;
        switch (type) {
            case QuoteInfoType.Start:
                icon = <StartIcon />
                break;
            case QuoteInfoType.Car:
                icon = isActive ? <DriverIconActive /> : <DriverIconNormal />;
                break;
            case QuoteInfoType.Driver:
                icon = isActive ? <DriverIconActive /> : <DriverIconNormal />
                break;
            case QuoteInfoType.Quote:
                icon = isActive ? <StartIcon /> : <StartIcon />
                break;
        }

        return icon;
    }


    // const getIsComplete = (type) => {

    //     let isComplete = false;
    //     switch (type) {
    //         case QuoteInfoType.Start:
    //             isComplete = props.currentState === ;
    //             break;
    //         case QuoteInfoType.Car:
    //             isComplete = props.currentState >= props.numberOfStartState && props.currentState < props.numberOfCarState;
    //             break;
    //         case QuoteInfoType.Driver:
    //             isComplete = props.currentState >= props.numberOfCarState && props.currentState < props.numberOfDriverState;
    //             break;
    //         case QuoteInfoType.Quote:
    //             isComplete = props.currentState >= props.numberOfDriverState && props.currentState < props.numberOfQuoteState;
    //             break;
    //     }

    //     return isComplete;
    // }

    const styles = isMobileDevice ? mobileStyles : desktopStyles;

    return (<View style={styles.tabView}>
        {AutoTabData.map((item, index) => {
            const isActive = props.sectionIndex === index;
            const isComplete = index < props.sectionIndex;
            return <Pressable style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
                onPress={() => { props.onTabSelected(item.type) }}>
                <TabItem title={item.title} isActive={isActive} isComplete={isComplete}>
                    {isComplete ? <CompleteIcon /> : getIcon(item.type, isActive)}
                </TabItem>
            </Pressable>
        })}
    </View>);
}

const desktopStyles = StyleSheet.create({
    tabView: {
        width: "40%",
        height: window.innerHeight * 0.1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        position: "relative",
        alignSelf: "center",
        minWidth: 600
    }
});

const mobileStyles = StyleSheet.create({
    tabView: {
        width: "100%",
        height: window.innerHeight * 0.1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        position: "relative"
    }
});
export default AutoTab;