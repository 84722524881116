import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function PrivacyPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 22, fontWeight: "700", textAlign: "center" }]}>{`Privacy Policy `}</Text>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 20, fontWeight: "600", textAlign: "center" }]}>{`Last Updated: Jan 23 2024`}</Text>
            <Text style={styles.description}>{`JustEatingBetter.com (""JustEatingBetter.com"", ""we"", ""our"", or ""us"") values your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide when accessing or using our website(s) and app(s) (collectively, the ""Services"").`}</Text>
            <Text style={styles.description}>{`By accessing or using our Services, you consent to the terms of this Privacy Policy. If you do not agree with any aspect of this Privacy Policy, please do not use our Services.`}</Text>
            <Text style={styles.title}>{`Information We Collect`}</Text>
            <Text style={styles.description}>{`We collect various types of information when you interact with our Services, including:`}</Text>
            <Text style={styles.subDescription}>{`\tPersonal Information: When you register for an account or contact us, we may collect personal information such as your name, email address, and phone number.`}</Text>
            <Text style={styles.subDescription}>{`\tUsage Information: We may collect information about your use of the Services, including the pages you visit, the features you use, and the actions you take.`}</Text>
            <Text style={styles.subDescription}>{`\tDevice Information: We may collect information about the device you use to access our Services, such as your device type, operating system, and IP address.`}</Text>
            <Text style={styles.subDescription}>{`\tCookies and Similar Technologies: We may use cookies and similar technologies to collect information about your interactions with our Services and to personalize your experience.`}</Text>
            <Text style={styles.title}>{`How We Use Your Information`}</Text>
            <Text style={styles.description}>{`We may use the information we collect for various purposes, including:`}</Text>
            <Text style={styles.subDescription}>{`\tTo provide and improve our Services, including to personalize your experience and offer tailored content and recommendations.`}</Text>
            <Text style={styles.subDescription}>{`\tTo communicate with you about your account, transactions, and inquiries, and to send you updates, newsletters, and promotional materials.`}</Text>
            <Text style={styles.subDescription}>{`\tTo analyze and monitor trends, usage, and activities related to our Services.`}</Text>
            <Text style={styles.subDescription}>{`\tTo detect, investigate, and prevent fraudulent or unauthorized activities.`}</Text>
            <Text style={styles.subDescription}>{`\tTo comply with legal obligations and enforce our Terms of Use and other policies.`}</Text>
            <Text style={styles.title}>{`How We Share Your Information`}</Text>
            <Text style={styles.description}>{`We may share your information with third parties in the following circumstances:`}</Text>
            <Text style={styles.subDescription}>{`\tWith service providers, contractors, and other third parties who help us operate, maintain, and improve our Services.`}</Text>
            <Text style={styles.subDescription}>{`\tWith your consent or at your direction, including when you choose to share information with third-party integrations or platforms.`}</Text>
            <Text style={styles.subDescription}>{`\tIn connection with a merger, acquisition, or sale of assets, in which case your information may be transferred to the acquiring entity.`}</Text>
            <Text style={styles.subDescription}>{`\tWhen required by law or to protect our rights, property, or safety, or the rights, property, or safety of others.`}</Text>
            <Text style={styles.title}>{`Your Choices`}</Text>
            <Text style={styles.description}>{`You have certain choices regarding the information we collect and how we use it:`}</Text>
            <Text style={styles.subDescription}>{`\tYou can choose not to provide certain information, but this may limit your ability to use certain features of our Services.`}</Text>
            <Text style={styles.subDescription}>{`\tYou can opt out of receiving promotional emails from us by following the instructions in those emails.`}</Text>
            <Text style={styles.subDescription}>{`\tYou can adjust your device settings to control or disable cookies and similar technologies.`}</Text>
            <Text style={styles.title}>{`Security`}</Text>
            <Text style={styles.description}>{`We take reasonable measures to protect the security of your information and prevent unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.`}</Text>
            <Text style={styles.title}>{`Children's Privacy`}</Text>
            <Text style={styles.description}>{`Our Services are not intended for children under the age of 18, and we do not knowingly collect personal information from children under the age of 18. If you believe that we may have collected information from a child under the age of 18, please contact us immediately.`}</Text>
            <Text style={styles.title}>{`Changes to This Privacy Policy`}</Text>
            <Text style={styles.description}>{`We may update this Privacy Policy from time to time, and any changes will be effective immediately upon posting. We encourage you to review this Privacy Policy periodically for updates.`}</Text>
            <Text style={styles.title}>{`Contact Us`}</Text>
            <Text style={styles.description}>{`If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at health@JustEatingBetter.com.`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "FamiljenGrotesk-Regular",
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "FamiljenGrotesk-Regular",
        },
        subDescription: {
            color: "#0B3C49",
            fontSize: 14,
            fontWeight: "400",
            position: "relative",
            paddingVertical: 3,
            fontFamily: "FamiljenGrotesk-Regular",
        },
    }
)

export default PrivacyPage;
