import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import AutoTab from '../components/AutoTab';
import { View, Text, Pressable, TextInput, StyleSheet, Image, Linking } from "react-native";
import { useState, useCallback, useEffect } from 'react';
import { AutoTabType } from '../constants/autoTabType';
import Footer from '../components/Footer';
import { isMobile } from '../helpers/deviceUtils';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { INPUT_TYPE } from '../constants/inputType';
import axios from 'axios';
import { scale } from 'react-native-size-matters';
import { QuoteInfoType } from '../constants/quoteInfoType';
import { useSearchParams } from 'react-router-dom';
import MultiChoiceQuestion from '../components/MultiChoiceQuestion';

const Tab = createBottomTabNavigator();

const isMobileDevice = isMobile();
const CarInsuranceInfo = {
    [QuoteInfoType.Start]: [{
        title: "Hi, let's start with a few questions",
        description: "How many servings of vegetables do you have per week?",
        errorMessage: "",
        inputMode: "numeric",
        type: INPUT_TYPE.NUMBER,
        isComplete: false,
        content: "",
        maxLength: 2,
    },
    {
        title: "Do you currently attend a gym?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Yes",
            "No",
        ]
    },
    {
        title: "Select some foods below you might like to eat",
        selectedOptions: [],
        errorMessage: "",
        isComplete: false,
        options: [
            "Beef",
            "eggs",
            "brocoli",
            "fish",
            "carrots",
            "chicken",
            "nuts",
            "strawberries"
        ]
    },
    {
        title: "Do you have a weight loss goal ? if so please advise your current weight and desired loss",
        errorMessage: "",
        placeholder: "",
        inputMode: "text",
        isComplete: false,
        content: "",
    }],
    [QuoteInfoType.Car]: [{
        title: "Do you cook for yourself or other family members also?",
        placeholder: "",
        inputMode: "text",
        isComplete: false,
        content: "",
    }, {
        title: "Would you like to be contacted for a consulation on meal preperation and diet management?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Yes",
            "No",
        ]
    }, {
        title: "Would other family members also like to participate in any meal preperation training?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Yes",
            "No",
        ]
    }],
    [QuoteInfoType.Quote]: [
        {
            title: "Please advise any more information that may help our consulants assist with your enquiry",
            placeholder: "",
            inputMode: "text",
            isComplete: false,
            content: "",
        },
        {
            isComplete: false,
            contents: [
                {
                    title: "Email address",
                    placeholder: "Enter your email address",
                    inputMode: "email",
                    type: INPUT_TYPE.EMAIL,
                    errorMessage: "",
                    content: "",
                },
                {
                    title: "Phone number",
                    placeholder: "(___) ___ ____",
                    errorMessage: "",
                    inputMode: "tel",
                    type: INPUT_TYPE.PHONE,
                    content: "",
                    maxLength: 20,
                },
            ],
        }],
}

const CarInsuranceKeys = Object.keys(CarInsuranceInfo);

function AutoInsuranceTabs({ route, navigation }) {
    const [sectionIndex, setSectionIndex] = useState(0);
    const [blockIndex, setBlockIndex] = useState(0);
    const [searchParams] = useSearchParams();
    const zipCode = searchParams.get('zipCode')
    const [autoData, setAutoData] = useState(Object.assign({}, CarInsuranceInfo));
    const styles = isMobileDevice ? mobileStyles : desktopStyles;

    useEffect(() => {
        sendQuote();
    }, [sectionIndex]);

    const sendQuote = async () => {
        if (sectionIndex >= CarInsuranceKeys.length) {
            let data = ""
            for (let index = 0; index < CarInsuranceKeys.length; index++) {
                const quoteSection = CarInsuranceInfo[CarInsuranceKeys[index]];
                for (let quoteSectionIndex = 0; quoteSectionIndex < quoteSection.length; quoteSectionIndex++) {
                    data += generateQuoteInfo(quoteSection[quoteSectionIndex]);
                }
            }

            console.log(data);

            try {

                const response = await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
                    service_id: "service_v6jln6h",
                    template_id: "template_ddokoqn",
                    user_id: "23lB37zM51IUM9_c3",
                    template_params: {
                        message: data
                    }
                })

                console.log(response)
            } catch (error) {
                console.log(error);
            }
        }
    }

    const generateQuoteInfo = (item) => {
        if (item.contents) {
            let quoteInfos = "";
            for (let index = 0; index < item.contents.length; index++) {
                const quoteInfo = generateQuoteInfo(item.contents[index]);
                quoteInfos += quoteInfo;
            }

            return quoteInfos;
        }

        const question = item.description || item.title || item.placeholder;
        const answer = item.selectedOptions != null ? item.selectedOptions.join(", ") : (item.selectedOption || item.content);

        return `${question}${question.trim().endsWith("?") ? "" : ":"} ${answer}\n`;
    }
    const onPress = async () => {
        if (sectionIndex < CarInsuranceKeys.length) {
            if (CarInsuranceInfo[CarInsuranceKeys[sectionIndex]].length > blockIndex + 1) {
                setBlockIndex(blockIndex + 1);
            } else {
                setBlockIndex(0);
                setSectionIndex(sectionIndex + 1);
            }
        }
    }

    const renderTitle = (title) => {
        return (
            <Text style={styles.title}>{title}</Text>
        )
    }

    const renderDescription = (description, customStyle) => {
        return (
            <Text style={[styles.description, customStyle]}>{description}</Text>
        )
    }

    const renderError = (errorMessage) => {
        return (
            <Text style={[styles.description, { color: "red" }]}>{errorMessage}</Text>
        )
    }

    const renderNextButton = (isActive, item) => {
        return (
            <Pressable style={[styles.button, isActive && styles.buttonActive]}
                disabled={!isActive}
                onPress={() => {
                    if (item) {
                        const isValid = isDataValid(item);
                        if (!isValid) {
                            setAutoData(Object.assign({}, CarInsuranceInfo));
                            return;
                        }
                    }

                    onPress();
                }}>
                <Text style={[styles.buttonContent]}>{"NEXT"}</Text>
            </Pressable>
        )
    }

    const isDataValid = (item) => {
        let isValid = true;
        if (item.contents) {
            for (let i = 0; i < item.contents.length; i++) {
                isValid = isDataValid(item.contents[i]);
                if (!isValid) {
                    return false;
                }
            }
        }

        if (item.content) {
            item.errorMessage = "";
            if (item.type === INPUT_TYPE.EMAIL) {
                isValid = validator.isEmail(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.EMAIL;

                    return false;
                }
            }

            if (item.type === INPUT_TYPE.PHONE) {
                isValid = validator.isMobilePhone(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.PHONE;

                    return false;
                }
            }

            if (item.type === INPUT_TYPE.ZIP_CODE) {
                isValid = validator.isPostalCode(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.ZIP_CODE;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.AGE) {
                isValid = validator.isNumeric(item.content) && item.content.length < 4;
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.AGE;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.YEAR) {
                isValid = validator.isNumeric(item.content) && item.content.length === 4;
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.YEAR;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.NUMBER) {
                isValid = validator.isNumeric(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.GENERIC;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.DATE) {
                isValid = validator.isDate(item.content, {
                    format: "YYYY/MM/DD",
                });
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.DATE;
                    return false;
                }
            }
        }

        return true;
    }

    const renderOptionButton = (isActive, content, onSelect) => {
        return (
            <Pressable style={[styles.optionButton, isActive && styles.optionButtonActive]}
                onPress={() => onSelect(content)}>
                <Text style={styles.optionButtonContent}>{content}</Text>
            </Pressable>
        )
    }

    const renderTextInput = (data) => {
        return (
            <View style={styles.textInputView}>
                <TextInput placeholder={data.placeholder}
                    style={styles.textInput}
                    onChangeText={(newText) => {
                        data.content = newText;
                        setAutoData(Object.assign({}, CarInsuranceInfo));
                    }}
                    inputmode={data.inputMode}
                    value={data.content}
                    maxLength={data.maxLength || 1000}></TextInput>
            </View>
        )
    }

    const renderOptionButtons = (item) => {
        const onSelect = (content) => {
            item.selectedOption = content;
            setAutoData(Object.assign({}, CarInsuranceInfo))
        };
        return (
            <View style={styles.columnDirectionView}>
                {item.options.map((data) => renderOptionButton(data === item.selectedOption, data, onSelect))}
            </View>
        )
    };

    const onMultiChoiceChange = useCallback(item => (option, isSelected) => {
        if (isSelected) {
            if (!item.selectedOptions.includes(option)) {
                item.selectedOptions.push(option);
            }
        } else {
            const index = item.selectedOptions.indexOf(option);
            if (index >= 0) {
                item.selectedOptions.splice(index, 1);
            }
        }

        setAutoData(Object.assign({}, CarInsuranceInfo));

    }, [autoData]);


    const renderMultiChoiceView = (item) => {
        return (
            <View style={styles.columnDirectionView}>
                <MultiChoiceQuestion {...item} onChange={onMultiChoiceChange(item)} />
                {renderNextButton(item.selectedOptions.length > 0)}
            </View>
        )
    };

    const renderViewWithTextInput = (item) => {
        return (
            <View style={styles.columnDirectionView}>
                {item.title && renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {item.errorMessage && renderError(item.errorMessage)}
                {renderTextInput(item)}
                {item.isComplete != null && renderNextButton(item.content !== "", item)}
            </View>
        )
    }

    const renderViewWithButtonOptions = (item) => {
        return (
            <View style={[styles.columnDirectionView, { alignItems: "flex-start" }]}>
                {renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {renderOptionButtons(item)}
                {renderNextButton(item.selectedOption !== "")}
            </View>
        )
    }

    const renderViewWithMultipleTextInput = (item) => {
        const firstEmptyField = item.contents.findIndex((data) => data.content === "");
        return (
            <View style={styles.columnDirectionView}>
                {renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {item.contents.map((data) => renderViewWithTextInput(data))}
                {renderNextButton(firstEmptyField === -1, item)}
            </View>
        )
    }

    const renderCompleteView = () => {
        return (
            <View style={[styles.columnDirectionView, { alignItems: "center" }]}>
                {renderTitle(messages.complete)}
                <Image source={require('../assets/complete.png')}
                    style={styles.completeImage}
                    resizeMode='contain' />
                {renderDescription(messages.completeDescription, { textAlign: "center" })}
            </View>
        )
    }

    const renderQuoteDetailView = (item) => {
        if (item.options != null) {
            if (item.selectedOption != null) {
                return renderViewWithButtonOptions(item);
            } else {
                return renderMultiChoiceView(item);
            }
        } else if (item.contents != null) {
            return renderViewWithMultipleTextInput(item);
        } else if (item.content != null) {
            return renderViewWithTextInput(item);
        }
    }
    return (
        <>
            <View style={styles.detailView}>
                <View style={[styles.detailContent, !isMobileDevice && { width: "40%", minWidth: 600 }]}>
                    {sectionIndex >= CarInsuranceKeys.length ? renderCompleteView()
                        : renderQuoteDetailView(CarInsuranceInfo[CarInsuranceKeys[sectionIndex]][blockIndex])}
                </View>
            </View >
        </>
    );
}

const baseStyles = StyleSheet.create(
    {
        title: {
            color: "#111111",
            fontSize: 20,
            lineHeight: "100%",
            fontWeight: "600",
            fontFamily: "FamiljenGrotesk-Regular",
            marginVertical: 10
        },
        description: {
            color: "#111111",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            fontFamily: "FamiljenGrotesk-Regular",
            marginVertical: 10,
            opacity: 0.75,
        },
        button: {
            borderRadius: 60,
            backgroundColor: "rgba(199, 199, 199, 0.45)",
            flex: 1,
            marginHorizontal: 5,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 15,
            height: 40,
            width: "25%",
            alignSelf: "flex-end"
        },
        buttonActive: {
            backgroundColor: "white"
        },
        buttonContent: {
            color: "#000000",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "700",
            fontFamily: "FamiljenGrotesk-Regular",
            padding: 14,
        },
        optionButton: {
            marginVertical: scale(5),
            height: scale(40),
            borderRadius: 7,
            borderColor: "rgba(11, 60, 73, 0.35)",
            borderWidth: 1,
            backgroundColor: "#F0FAF5",
            flex: 1,
            marginHorizontal: 5,
            alignItems: "center",
            width: "100%"
        },
        optionButtonActive: {
            borderWidth: 2,
            borderColor: "#70E189",
            backgroundColor: "#B8F0C4"
        },
        optionButtonContent: {
            color: "#000000",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            fontFamily: "FamiljenGrotesk-Regular",
            marginVertical: 15,
            opacity: 0.75
        },
        textInputView: {
            marginVertical: 5,
            width: "100%",
            height: 40,
            flexDirection: "row"
        },
        textInput: {
            borderRadius: 7,
            borderColor: "rgba(11, 60, 73, 0.35)",
            borderWidth: 1,
            width: "100%",
            fontFamily: "FamiljenGrotesk-Regular",
            paddingHorizontal: 15
        },
        columnDirectionView: {
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start"
        },
        completeImage: {
            width: "80%",
            height: 200
        },
        detailView: {
            width: "100%",
            height: window.innerHeight * 0.9,
            backgroundColor: "transparent",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute"
        },
        detailContent: {
            width: "90%",
            backgroundColor: "rgba(255, 234, 147, 1)",
            marginTop: scale(30),
            borderRadius: 10,
            borderColor: "rgba(11, 60, 73, 0.50)",
            borderWidth: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: scale(20)
        },
    });

const desktopStyles = StyleSheet.create({
    ...baseStyles,
    button: {
        ...baseStyles.button,
        width: "25%",
        alignSelf: "flex-end"
    },
})



const mobileStyles = StyleSheet.create({
    ...baseStyles,
    button: {
        ...baseStyles.button,
        width: "100%"
    },
})
const messages = {
    complete: "Complete!",
    completeDescription: "One of our expert trainers will be in contact with you shortly!"
}

export default AutoInsuranceTabs;