import { View, Text, StyleSheet, ImageBackground, Button, TextInput } from 'react-native';
import { useState } from 'react';
import Footer from '../components/Footer';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { isMobile } from '../helpers/deviceUtils';
import { Pressable } from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import AutoInsuranceTabs from './autoInsurance';

const isMobileDevice = isMobile();


function AutoHomePage({ navigation }) {
    const [showInfo, setShowInfo] = useState(false);
    const styles = isMobileDevice ? mobileStyles : desktopStyles;
    const navigate = useNavigate()

    return (
        <View style={styles.root}>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <Text style={styles.title}>{messages.title}</Text>
                </View>
            </View>

            <View style={styles.contentView}>
                <ImageBackground source={require('../assets/background.png')}
                    style={styles.image} />
                <View source={require('../assets/background.png')}
                    style={styles.darkBackground} />

                <View style={styles.mainContent} >
                    <Text style={styles.description}>{messages.topDescription}</Text>

                    <Pressable style={[styles.buttonView]}
                        onPress={() => {
                            setShowInfo(true);
                        }}>
                        <Text style={[styles.buttonContent]}>{messages.seeQuotes}</Text>
                    </Pressable>
                    <Text style={styles.description}>{messages.bottomDescription}</Text>
                </View>

                {showInfo && <AutoInsuranceTabs />}
            </View>
            <Footer navigation={navigation} style={styles.footer}>

            </Footer>

        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#91E9EC',
        },
        header: {
            height: window.innerHeight * 0.1,
            backgroundColor: '#FFEA93',
            justifyContent: "center"
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontFamily: "FamiljenGrotesk-Regular",
            fontWeight: "800"
        },
        contentView: {
            height: window.innerHeight * 0.9,
            width: "100%",
        },
        image: {
            width: "100%",
            height: '100%',
            resizeMode: 'cover',
        },
        darkBackground: {
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)"
        },
        description: {
            color: "white",
            fontWeight: "700",
            textAlign: "center",
            fontFamily: "FamiljenGrotesk-Regular",
        },
        error: {
            color: "red",
            fontWeight: "400",
            fontFamily: "FamiljenGrotesk-Regular",
            lineHeight: 24
        },
        inputView: {
            flexDirection: "row",
            marginTop: 40
        },
        textInput: {
            backgroundColor: "white",
            borderRadius: 10,
            fontWeight: 400,
            fontFamily: "FamiljenGrotesk-Regular",
            padding: 15,
        },
        footer: {
            position: "absolute",
            marginTop: window.innerHeight * 0.94,
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
        },
        buttonView: {
            backgroundColor: "#FFEA93",
            position: "relative",
            borderRadius: 10,
            fontWeight: "800",
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 50,
            width: 150,
        },
        buttonContent: {
            color: "#000000",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "700",
            padding: 14,
            fontFamily: "FamiljenGrotesk-Regular",
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        header: {
            ...baseStyles.header,
            width: '100%',
        },
        headerContent: {
            marginLeft: 215,
            flexDirection: "row"
        },
        title: {
            ...baseStyles.title,
            fontSize: 22,
        },
        contentView: {
            ...baseStyles.contentView,
            alignItems: "center",
            justifyContent: "center"
        },
        mainContent: {
            justifyContent: "center",
            width: "50%",
            minWidth: "500",
            alignItems: "center",
            marginBottom: 100,
            position: "absolute"
        },
        description: {
            ...baseStyles.description,
            fontSize: 64,
            lineHeight: 66
        },
        error: {
            ...baseStyles.error,
            fontSize: 24,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 12,
            width: 220
        },
    }
)


const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            height: "100%",
            width: '100%',
        },
        header: {
            ...baseStyles.header,
            width: window.innerWidth,
        },
        headerContent: {
            marginLeft: "10%",
            flexDirection: "row",
            alignItems: "center"
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "flex-start"
        },
        mainContent: {
            marginLeft: "10%",
            width: "80%",
            position: "absolute",
            marginTop: 65,
            paddingRight: 20
        },
        description: {
            ...baseStyles.description,
            fontSize: 30,
            lineHeight: 38
        },
        error: {
            ...baseStyles.error,
            fontSize: 20,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 10,
            width: "60%"
        },
    }
)

const messages = {
    title: "Just Eating Better",
    topDescription: "Start eating right & feeling better!",
    bottomDescription: "Let’s make a plan together!",
    seeQuotes: "START NOW",
}

export default AutoHomePage;
