import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function TermsPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 22, fontWeight: "700", textAlign: "center" }]}>{`Our Terms of Use`}</Text>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 20, fontWeight: "600", textAlign: "center" }]}>{`Last Updated: Jan 23 2024`}</Text>

            <Text style={styles.description}>{`Welcome to JustEatingBetter.com, your go-to destination for nourishing and wholesome options tailored to support your journey towards a healthier lifestyle. As a health-focused company committed to your well-being, we're excited to introduce you to our online referral service, accessible via our website(s) and app(s), designed to empower you with the tools and resources you need to thrive.`}</Text>
            <Text style={styles.description}>{`Before embarking on your wellness journey with us, we kindly ask you to review the following important terms and conditions of use (the ""Terms of Use""), now accessible through our dedicated health@ email for your convenience. These terms serve as a guiding framework for your interaction with our platform, ensuring a seamless and enriching experience for all.`}</Text>

            <Text style={styles.title}>{`Key Highlights of Our Terms of Use:`}</Text>
            <Text style={styles.description}>{`Eligibility and Registration: Join our community by registering for an account, where you'll gain access to exclusive features and content. We prioritize the accuracy and completeness of your registration information, guaranteeing a personalized experience tailored to your needs.`}</Text>
            <Text style={styles.description}>{`License: Enjoy a limited, personal, non-exclusive license to explore our Services, with a commitment to your privacy and security. Our aim is to provide you with a seamless experience while protecting your rights and interests.`}</Text>
            <Text style={styles.description}>{`Proprietary Rights: Respect for intellectual property is at the core of our values. We ensure that all content provided through our Services is protected and owned by us or our licensors, promoting a fair and equitable environment for all users.`}</Text>
            <Text style={styles.description}>{`Use of the Services: Your well-being is our priority. We encourage responsible and mindful engagement with our platform, promoting healthy habits and behaviors that contribute to your overall wellness.`}</Text>
            <Text style={styles.description}>{`Termination or Suspension: In the rare event of account termination or suspension, we prioritize transparency and fairness, ensuring that all actions taken align with our commitment to maintaining a safe and supportive community.`}</Text>
            <Text style={styles.description}>{`Loyalty Program: Join our loyalty program designed to reward your commitment to a healthier lifestyle. Participation is open to all eligible users, offering exciting benefits without the need for additional purchases.`}</Text>
            <Text style={styles.description}>{`User Submissions: Your voice matters to us. Share your insights and experiences through user submissions, knowing that your contributions are valued and respected within our community.`}</Text>
            <Text style={styles.description}>{`Feedback: We welcome your feedback as we continuously strive to enhance your experience. Your input helps us shape our platform to better meet your needs and expectations.`}</Text>
            <Text style={styles.description}>{`Indemnification: We prioritize your safety and security. Our indemnification policy ensures that you're protected from any potential claims or disputes arising from your use of our Services.`}</Text>
            <Text style={styles.description}>{`Disclaimer of Warranty: While we strive for excellence, we acknowledge that perfection is a journey, not a destination. Our disclaimer of warranty reflects our commitment to transparency and accountability.`}</Text>
            <Text style={styles.description}>{`Limitation of Liability: Your peace of mind is paramount. We take every measure to minimize risk and ensure that our liability is proportionate to the value we provide.`}</Text>
            <Text style={styles.description}>{`Release: By using our Services, you agree to release us from any liability arising from disputes or interactions within our community, fostering a culture of mutual respect and understanding.`}</Text>
            <Text style={styles.description}>{`Governing Law and Forum: We operate within a framework of integrity and compliance. Our commitment to legal and ethical standards ensures a fair and equitable environment for all users.`}</Text>
            <Text style={styles.description}>{`Binding Arbitration: In the rare event of disputes, our binding arbitration process provides a structured framework for resolution, prioritizing efficiency and fairness.`}</Text>
            <Text style={styles.description}>{`Force Majeure: We prioritize your safety and security. Our force majeure clause ensures that unforeseen circumstances beyond our control do not disrupt your experience with our platform.`}</Text>
            <Text style={styles.description}>{`Relationship of the Parties: Clarity and transparency are the cornerstones of our relationship with you. We operate as independent entities, fostering trust and mutual respect in all our interactions.`}</Text>
            <Text style={styles.description}>{`Notice; Consent to Electronic Notice: We value your privacy and convenience. Our commitment to electronic communication ensures seamless interaction and accessibility for all users.`}</Text>
            <Text style={styles.description}>{`Third-Party Links: Your safety is our priority. Our disclaimer regarding third-party links reflects our commitment to protecting you from potential harm or misinformation.`}</Text>
            <Text style={styles.description}>{`App Distributors: We prioritize transparency and clarity. Our disclosure regarding app distributors ensures that you have the information you need to make informed decisions about your use of our platform.`}</Text>
            <Text style={styles.description}>{`Miscellaneous: Our commitment to fairness and transparency extends to every aspect of our operations. We value your trust and strive to uphold the highest standards of integrity in all our interactions.`}</Text>
            <Text style={styles.description}>{`No Unsolicited Submissions: We respect your creative rights. Our policy on unsolicited submissions ensures that your original work is protected and respected within our community.`}</Text>
            <Text style={styles.description}>{`Intellectual Property Infringement: Your rights are important to us. Our commitment to protecting intellectual property ensures that your creative contributions are safeguarded against infringement.`}</Text>
            <Text style={styles.description}>{`Questions: We're here to support you every step of the way. If you have any questions or concerns, please don't hesitate to reach out to us at health@JustEatingBetter.com.`}</Text>
            <Text style={styles.description}>{`Thank you for choosing JustEatingBetter.com`}</Text>
        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "FamiljenGrotesk-Regular",
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "FamiljenGrotesk-Regular",
        },
    }
)
export default TermsPage;
