import { useCallback } from 'react';
import { View, Text, StyleSheet, Linking } from 'react-native';
import { CheckBox } from 'react-native-web';
import { useNavigate } from 'react-router-dom';


const MultiChoiceQuestion = (props) => {
    const { options, selectedOptions, onChange, title, id, errorMessage } = props;

    const onHandleChange = useCallback(option => (isSelected) => {
        onChange?.(option, isSelected);
    }, [])

    const renderCheckboxItem = useCallback((option) => {
        const isSelected = selectedOptions.includes(option);
        return (
            <View style={{ padding: 10, flexDirection: "row" }}>
                <CheckBox value={isSelected} onValueChange={onHandleChange(option)} style={{ width: 25, height: 25 }} />
                <View style={{ height: "100%", justifyContent: "center" }}>
                    <Text style={styles.checkBoxLabel}>{option}</Text>
                </View>
            </View>)

    }, [selectedOptions])

    return (
        <View style={[styles.parentView, props.style]}>
            <Text style={styles.title}>{title}</Text>
            {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
            {options.map(renderCheckboxItem)}
        </View>);
}


const styles = StyleSheet.create(
    {
        parentView: {
            borderColor: "blue",
            flexDirection: "column",
            marginVertical: 20,
        },
        title: {
            color: "#111111",
            fontSize: 20,
            lineHeight: "100%",
            fontWeight: "600",
            fontFamily: "FamiljenGrotesk-Regular",
            marginVertical: 10,
            flex: 2
        },
        checkBoxLabel: {
            color: "#111111",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            fontFamily: "FamiljenGrotesk-Regular",
            marginLeft: 20,
            opacity: 0.75,
        },
        error: {
            color: "red",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            fontFamily: "FamiljenGrotesk-Regular",
            marginVertical: 10,
            opacity: 0.75,

        },
    }
)

export default MultiChoiceQuestion;